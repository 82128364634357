@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/26e85d9fa6848492-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/042ec0556bc42c70-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/f56d80ff82f4fab3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/432f0d4ef40666fe-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/1512300f5b823773-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/08ae630e57390d22-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/56cb4df84be8ab76-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/6b5a1f7da03a3cd4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__sfProDisplay_6ba83e';
src: url(/_next/static/media/ae57e5818be16e6e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: italic;
}@font-face {font-family: '__sfProDisplay_Fallback_6ba83e';src: local("Arial");ascent-override: 101.52%;descent-override: 25.72%;line-gap-override: 0.00%;size-adjust: 93.79%
}.__className_6ba83e {font-family: '__sfProDisplay_6ba83e', '__sfProDisplay_Fallback_6ba83e'
}.__variable_6ba83e {--font-sf-pro-display: '__sfProDisplay_6ba83e', '__sfProDisplay_Fallback_6ba83e'
}

